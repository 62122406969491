
const API_URL = 'https://dev.admin.hydepark.beyond-creation.net/api';
const BASE_URL = 'https://dev.admin.hydepark.beyond-creation.net';

const fetchData = async (page, locale, populate) => {
	const response = await fetch(
		`${API_URL}/${page}?locale=${locale}&${populate}`
	)
	return response.json()
}
//to get dynamic data
const fetchDynamicData = async (page, slug, locale, populate) => {
	const response = await fetch(
		`${API_URL}/${page}?filters[slug][$eq]=${slug}&locale=${locale}&${populate}`
	)
	return response.json()
}

export { fetchDynamicData, fetchData, API_URL, BASE_URL };