import React, { useContext } from 'react'
import Link from 'next/link';
import { isMobileContext } from '../../contexts/isMobileContext';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { nextRouteContext } from '../../contexts/nextRouteContext';
import ArrowsSpliting from '../Animations/ArrowsSpliting';
const Button = ({ text, blackStyle, blackBorder, img, noHover, whiteBorder, blackStyleMaster, link, phaseColor, whiteButton, nextRoute, overlay }) => {
  const { isMobile } = useContext(isMobileContext)
  const router = useRouter()
  const { setNextRoute } = useContext(nextRouteContext);
  const goTo = () => {
    link ?
      router.push(link) : ""
    nextRoute && setNextRoute(nextRoute)
  }
  return (
    <div className={`bold ${!isMobile && !noHover && 'button--isi'} 
        button-section 
        ${blackStyle && 'black-button'}
        ${blackBorder && 'black-border'}
        ${whiteBorder && 'white-border'}
        ${blackStyleMaster && 'black-style-master'}
        ${whiteButton && 'white-button'}
        ${overlay && 'overlay-button'}
      `}
      onClick={() => { goTo() }}
      // in onclick function ==> , window.scrollTo(0, 0)
      style={{ background: phaseColor }}
    >
      {img && <div className='img-container'>
        <Image
          src={img}
          alt="map"
          layout="fill"
          objectFit="contain"
        />
      </div>}
      <span>
        <ArrowsSpliting>
          {text}
        </ArrowsSpliting>
      </span>
    </div>
  )
}

export default Button

