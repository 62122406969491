import Image from 'next/image'
import Router, { useRouter } from 'next/router'
import React, { useContext, useState } from 'react'
import { isMobileContext } from '../../contexts/isMobileContext'
import LangSwitcher from './LangSwitcher'
import { useTranslation } from "next-i18next"
import { phaseColorContext } from '../../contexts/phaseColorContext'
import { nextRouteContext } from '../../contexts/nextRouteContext'
import { AnimatePresence, motion, useCycle } from "framer-motion"
import DropDownMenu from './DropDownMenu'
import { subMenuAnimate } from "../../utils/Animations"
import { isMenuOpendContext } from '../../contexts/isMenuOpendContext'
import ArrowsSpliting from '../Animations/ArrowsSpliting'
const StickyNav = ({ tabs }) => {
    const { t } = useTranslation('common')
    const { asPath } = useRouter()
    const { setNextRoute } = useContext(nextRouteContext);
    const [isHover, toggleHover] = useState(false)
    const { phaseColor } = useContext(phaseColorContext);
    const { isMobile } = useContext(isMobileContext)
    const { setSearchMenuOpened, setIsMenuOpenedContext, isMenuOpenedContext, isDevelopMenuOpened, setIsDevelopMenuOpened, setIsBookingMenuOpened } = useContext(isMenuOpendContext)
    const handleClick = () => {
        if (isMobile) {
            setIsMenuOpenedContext(true)
        } else {
            setSearchMenuOpened(true)
        }
    }
    const toggleHoverMenu = (state, devMenuState) => {
        toggleHover(state)
        setIsDevelopMenuOpened(devMenuState)
    }
    return (
        <>
            <div className={`sticky-container ${isMenuOpenedContext && 'open-menu'}`} style={{ background: phaseColor }}>
                <LangSwitcher>
                    <div className="lang-switcher">{`${t("navbar.lang")}`}</div>
                </LangSwitcher>
                <div className='links-container bold'>
                    <div onClick={() => { setNextRoute(t("navbar.who_we")); Router.push('/who-we-are') }}>
                        <div className={`link ${Router.asPath.includes('/who-we-are') ? 'active' : ''}`}>
                            <ArrowsSpliting>{`${t("navbar.who_we")}`}</ArrowsSpliting>
                        </div>
                    </div>
                    <motion.div
                        onHoverStart={() => toggleHoverMenu(true, true)}
                        onHoverEnd={() => toggleHoverMenu(false, false)}
                        className={`link ${asPath.includes('/projects') ? 'active' : ''}`}>
                        <ArrowsSpliting>{`${t("navbar.developments")}`}</ArrowsSpliting>
                        <div className='icon-container'>
                            <Image
                                src={`${asPath.includes('/projects') ? '/icons/arrow-down-black.svg' : '/icons/arrow-down.svg'}`}
                                alt="logo"
                                objectFit="contain"
                                layout="fill"
                            />
                        </div>
                    </motion.div>
                    <div onClick={() => { setNextRoute(t("navbar.services")); Router.push('/services') }}>
                        <div className={`link ${Router.asPath.includes('/services') ? 'active' : ''}`}>
                            <ArrowsSpliting>{`${t("navbar.services")}`}</ArrowsSpliting>
                        </div>
                    </div>
                    <div>
                        <div onClick={() => { setNextRoute(t("navbar.360_works")); Router.push('/360-works') }}>
                            <div style={{ direction: "ltr" }} className={`link ${Router.asPath.includes('/360-works') ? 'active' : ''}`}>
                                <ArrowsSpliting works>{`360 WORKS`}</ArrowsSpliting>
                            </div>
                        </div>
                    </div>
                    <div onClick={() => { setNextRoute(t("navbar.media_center")); Router.push('/media-center') }} >
                        <div className={`link ${Router.asPath.includes('/media-center') ? 'active' : ''}`}>
                            <ArrowsSpliting>{`${t("navbar.media_center")}`}</ArrowsSpliting>
                        </div>
                    </div>
                    <div onClick={() => { setNextRoute(t("navbar.contactUs")); Router.push('/contact-us') }}>
                        <div className={`link ${Router.asPath.includes('/contact-us') ? 'active' : ''}`}>
                            <ArrowsSpliting>{`${t("navbar.contactUs")}`}</ArrowsSpliting>
                        </div>
                    </div>
                </div>
                <div className='contact-container'>
                    <a href="tel://16696" target="_blank" rel="noreferrer" aria-label="number">
                        <div className='phone' style={{ background: isMobile && `${phaseColor}`, filter: isMobile && "brightness(0.85)" }}>
                            <div className='icon-img'>
                                <Image
                                    src={'/icons/phoneNav.svg'}
                                    alt="icon"
                                    objectFit="contain"
                                    layout="fill"
                                    priority
                                />
                            </div>
                            {!isMobile &&
                                <ArrowsSpliting>
                                    <span>
                                        16696
                                    </span>
                                </ArrowsSpliting>
                            }
                        </div>
                    </a>
                    {isMobile && <div className='search booknow' onClick={() => setIsBookingMenuOpened(true)}>
                        <span>
                            <ArrowsSpliting>{`${t("common.book_now")}`}</ArrowsSpliting>
                        </span>
                    </div>}
                    <div className='search' onClick={() => handleClick()} style={{ background: `${phaseColor}`, filter: "brightness(0.85)" }}>
                        {isMobile ? <div className={`burger-menu ${isMenuOpenedContext && 'open'}`}>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                            :
                            <div className='icon-img'>
                                <Image
                                    src={'/icons/search.svg'}
                                    alt="icon"
                                    objectFit="contain"
                                    layout="fill"
                                />
                            </div>}
                        {!isMobile && <span><ArrowsSpliting>{`${t("navbar.search")}`}</ArrowsSpliting></span>}
                    </div>

                    {!isMobile && <div className='search booknow' onClick={() => setIsBookingMenuOpened(true)}>
                        <span><ArrowsSpliting>{`${t("common.book_now")}`}</ArrowsSpliting></span>
                    </div>}
                </div>
            </div>
            <AnimatePresence>
                {!isMobile &&
                    <motion.div className={`dev-menu stickyNav`}
                        initial="exit"
                        animate={isHover && isDevelopMenuOpened ? "enter" : "exit"}
                        variants={subMenuAnimate}
                        onHoverStart={() => toggleHoverMenu(true, true)}
                        onHoverEnd={() => toggleHoverMenu(false, false)}
                    >
                        <DropDownMenu tabs={tabs} toggleHover={toggleHover} />
                    </motion.div>
                }
            </AnimatePresence>
        </>
    )
}

export default StickyNav